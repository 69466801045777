.faqContainer{
    margin-top: 40px;
    padding: 15px 90px 15px 90px !important;
}
.faqHeader{
    font-family: "Inter", sans-serif;
    font-weight: 700;
}
.faqSubHeader{
    font-family: 'Open Sans', sans-serif;
    font-weight: lighter !important;
    margin-left: 25px !important;
}

.faq-row{
    background-color: #111A22 !important;
    padding: 15px 15px !important;
    margin-top: 12px !important;
    border-radius: 5px !important;
}
.arrow-image{
    font-weight: 700;
}

.row-title-text{
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
}

.faqAnswer{
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 30px;
}
.faqBtn{
    background: transparent !important;
    border: none !important;
    outline: none !important;

}
.faqBtn:focus{
    border: none !important;
    outline: none !important;
}
.faqBtn:active{
    border: none !important;
    outline: none !important;
}
.faqSpan{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.row-content-text{
    padding-bottom: 20px !important;
}

.nav2 {
    padding-bottom: 0 !important;
}

@media only screen and (max-width: 768px) {
    .faqContainer {
        margin-top: 0px !important;
        padding: 45px 20px 45px 20px !important;
    }

}


@media only screen and (min-width: 1106px) and (max-width: 1183px) {
    .faqContainer {
        padding: 45px 20px 45px 20px !important;
    }

}
@media only screen and (min-width: 1025px) and (max-width: 1486px) {
    .faqContainer {
        padding: 45px 25px 45px 25px !important;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1092px) {
    .faqContainer {
        padding: 45px 25px 45px 25px !important;
    }

}
@media only screen and (max-width: 480px) {
    .faqContainer{
        margin-top: -10px !important;
        padding: 15px 10px 15px 10px !important;
    }
    .faqSubHeader{
        margin-left: 15px !important;
    }
    .row-title-text{
        font-size: 1rem !important;
    }

}